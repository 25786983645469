import Vue from "vue";
import Router from "vue-router";
import Main from "../components/Main.vue";
import BlogContent from "../components/BlogContent.vue";
import BlogFullList from "../components/BlogFullList.vue";
import PageNotFound from "../components/PageNotFound.vue";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      name: "Main",
      component: Main
    },
    {
      path: "/blog_full_list",
      name: "BlogList",
      component: BlogFullList
    },
    {
      path: "/welcome",
      name: "Main_welcome",
      component: Main
    },
    {
      path: "/company",
      name: "Main_company",
      component: Main
    },
    {
      path: "/products",
      name: "Main_products",
      component: Main
    },
    {
      path: "/pricing",
      name: "Main_pricing",
      component: Main
    },
    {
      path: "/services",
      name: "Main_services",
      component: Main
    },
    {
      path: "/use_case",
      name: "Main_use_case",
      component: Main
    },
    {
      path: "/team",
      name: "Main_team",
      component: Main
    },
    {
      path: "/blog/:uuid(.*)",
      name: "BlogContent",
      component: BlogContent
    },
    {
      path: "/news",
      name: "Main_news",
      component: Main
    },
    {
      path: "*",
      component: PageNotFound
    }
  ],
  scrollBehavior: function(to) {
    const behavior = navigator.userAgent.indexOf("Firefox") != -1 ? "auto" : "smooth";
    if (!to.name) {
      return;
    }
    if (to.name === "Main_welcome") {
      return { x: 0, y: 0, behavior };
    } else if (to.name.startsWith("Main")) {
      // Do not interfere with auto section change
      if (to.params.scrollDriven) {
        return;
      }
      return {
        selector: to.path.replace("/", "#"),
        behavior
      };
    }

    if (to.fullPath === "/welcome") {
      return { x: 0, y: 0 };
    } else if (to.hash) {
      return {
        selector: to.hash,
        // smooth scroll is not managed correctly by Firefox
        behavior
      };
    }
    return { x: 0, y: 0 };
  }
});
