<template>
  <div :style="cssProps">
    <div class="top">
      <router-link :to="`/blog/fr/${currentNews.news}`">Version française</router-link>
      |
      <router-link :to="`/blog/en/${currentNews.news}`">English version</router-link>
    </div>
    <vue-markdown class="blogContent" :source="blog.content"></vue-markdown>
    <div class="bottom">
      {{ footer }}
    </div>
  </div>
</template>

<script>
import blogs from "../blogs.js";
import VueMarkdown from "vue-markdown";
import moment from "moment";

export default {
  name: "BlogContent",
  props: {},
  computed: {
    currentNews: function() {
      const url = window.location.href.split("/");
      const language = url[url.length - 2];
      const news = url[url.length - 1];
      return {
        language: language,
        news: news
      };
    },
    blog: function() {
      const { uuid } = this.$route.params;
      return blogs.filter(b => b.id === uuid).pop();
    },
    date: function() {
      moment.locale(this.blog.language);
      let d = moment(this.blog.date, "YYYY-MM-DD");
      return d.format("LL");
    },
    footer: function() {
      return this.date + ", " + this.blog.author;
    },
    cssProps() {
      return {
        "--primary-color": this.$vuetify.theme.themes.light.primary,
        "--secondary-color": this.$vuetify.theme.themes.light.secondary
      };
    }
  },
  components: {
    VueMarkdown
  },
  mounted: function() {
    // remove footnote links
    this.$el.querySelectorAll(".footnote-backref").forEach(e => {
      e.remove();
    });

    // add link direction to new tab
    this.$el.querySelectorAll("div.blogContent > p > a").forEach(e => {
      e.setAttribute("target", "_blank");
    });
    // remove internal links as it doesn't work
    this.$el.querySelectorAll("a").forEach(e => {
      let id = e.getAttribute("id");
      if (id !== null) {
        let text = e.text;
        let parent = e.parentNode;
        e.remove();
        parent.textContent = text;
      }
    });
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.top {
  padding-top: 20px;
}

.bottom {
  display: flex;
  justify-content: space-between;
  margin: 20px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  color: grey;
  padding: 0 15px 0 15px;
}

.blogContent {
  margin: 40px;
  min-height: 100vh;
  max-width: 800px;
  text-align: justify;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px 0 15px;
}

.blogContent > h1 {
  color: var(--secondary-color);
  text-align: left;
}

.blogContent > h2 {
  color: var(--secondary-color);
}

.blogContent > p > img {
  max-width: 600px;
  background-color: white;
  display: block;
  margin: 0 auto;
  -webkit-filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.6));
  -moz-filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.6));
  -ms-filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.6));
  -o-filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.6));
  filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.6));
}
.blogContent > p > em {
  text-align: center;
  margin: 0 auto;
  display: block;
}
.blogContent > iframe {
  text-align: center;
  margin: 0 auto;
  display: block;
  margin-bottom: 1em;
}

table {
  border-collapse: collapse;
  text-align: center;
  vertical-align: middle;
}

th,
td {
  border: 1px solid black;
  padding: 8px;
}

th {
  background-color: var(--secondary-color);
  color: white;
}

.footnotes-sep {
  border-width: 0px;
}

.blogContent > pre {
  padding: 16px;
  overflow: auto;
  font-size: 85%;
  line-height: 1.45;
  background-color: #f6f8fa;
  border-radius: 6px;
  margin-bottom: 15px;
}

.theme--light.v-application code {
  background-color: rgb(0 0 0 / 0%) !important;
}

.footnotes {
  text-align: left;
}
</style>
