import { render, staticRenderFns } from "./BlogList.vue?vue&type=template&id=57bfa59a&scoped=true"
import script from "./BlogList.vue?vue&type=script&lang=js"
export * from "./BlogList.vue?vue&type=script&lang=js"
import style0 from "./BlogList.vue?vue&type=style&index=0&id=57bfa59a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/.aspect_rules_js/vue-loader@15.11.1_1120535650/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57bfa59a",
  null
  
)

export default component.exports